import React from "react";
import { Link, withRouter } from "react-router-dom";

import Container from "../Container/Container";

import "./Nav.scss";

const generateClassNames = (location, path) => {
  let classNames = ["nav__link"];
  if (location.pathname === path) {
    classNames.push("nav__link--active");
  }
  return classNames.join(" ");
};

const links = [
  { name: "Store", path: "https://www.scentsbysumma.com", external: true }
];

const Nav = ({ location }) => (
  <nav className="nav">
    <Container>
      <div className="nav__content">
        <Link to="/">
          <img
            alt="Summa Residential Header Logo - Black and Purple"
            className="nav__image"
            src="https://s3-us-west-1.amazonaws.com/www.summaresidential.com/logo--mixed.png"
          />
        </Link>
        <ul className="nav__links">
          {links.map(link => (
            <li key={link.path}>
              {link.external ? (
                <a
                  className="nav__link"
                  href={link.path}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link.name}
                </a>
              ) : (
                <Link
                  className={generateClassNames(location, link.path)}
                  to={link.path}
                >
                  {link.name}
                </Link>
              )}
            </li>
          ))}
          <li>
            <a
              className={generateClassNames(location, "/contact")}
              href="mailto:dross@summaresidential.com"
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
    </Container>
  </nav>
);

export default withRouter(props => <Nav {...props} />);
