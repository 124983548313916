import React from "react";

import Container from "../Container/Container";
import Nav from "../Nav/Nav";
import SocialLinks from "../SocialLinks/SocialLinks";

import "./App.scss";

export default ({ children }) => (
  <div className="app">
    <div className="app__bg">
      <Nav />
      <section className="app__content">{children}</section>
    </div>
    <Container>
      <h2 className="app__header">Summa Residential</h2>
    </Container>
    <section className="app__footer">
      <Container>
        <p>
          Summa Residential strives to provide the highest quality residential
          properties for both short and long term rentals.
        </p>
        <p>
          Summa (pronounced sue-ma) in Latin means "highest" - think of "summa
          cum laude" at graduation.
        </p>
      </Container>
    </section>
    <SocialLinks />
  </div>
);
