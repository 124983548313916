import dotenv from "dotenv";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";

import App from "./components/App/App";
import Home from "./components/Home/Home";

import "./index.scss";

dotenv.config();

ReactDOM.render(
  <Router>
    <App>
      <Route exact path="/" component={Home} />
    </App>
  </Router>,
  document.getElementById("root")
);
